<template>
  <div class="mb-3">
    <b-row
      class="mb-1 mx-0"
      align-h="end"
    >
      <small class="text-danger">{{ permissionErrorText }}</small>
    </b-row>
    <!-- Row Loop -->
    <b-row
      v-for="(member) in assignedMembers"
      :id="member.id"
      :key="member.id"
      ref="row"
      class="mb-1"
      align-v="center"
    >
      <!-- Name -->
      <b-col
        cols="2"
      >
        <b-form-input
          v-model="member.name"
          class="text-center text-primary border-secondary"
          size="lg"
          plaintext
        />
      </b-col>

      <!-- Email -->
      <b-col
        cols="6"
      >
        <b-form-input
          v-model="member.username"
          class="text-center text-primary border-secondary"
          size="lg"
          plaintext
        />
      </b-col>

      <!-- Permission -->
      <b-col
        cols="4"
      >
        <b-dropdown
          v-ripple="'rgba(113, 102, 240, 0.15)'"
          :text="resolvePermission(member.permission)"
          variant="primary"
          right
          :disabled="readOnly"
          block
        >
          <b-dropdown-item
            v-for="permission in permissionOptions"
            :key="permission.text"
            @click="member.permission = permission.value"
          >
            {{ permission.text }}
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item>
            결제 담당(읽기 전용)
          </b-dropdown-item>
          <b-dropdown-item>
            기술 담당(읽기/편집)
          </b-dropdown-item>
        </b-dropdown>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormInput, BRow, BCol, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { inject } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18nUtils()
    const resolvePermission = value => t(value)
    const assignedMembers = inject('assignedMembers')
    const permissionOptions = inject('permissionOptions')
    const permissionErrorText = inject('permissionErrorText')
    return {
      assignedMembers,
      permissionOptions,
      permissionErrorText,
      resolvePermission,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: visible;
  transition: .35s height;
}
</style>
