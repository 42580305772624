<template>
  <loading-component
    v-if="loading"
  />
  <error-component
    v-else-if="error"
    :error="error"
  />
  <app-form-layout v-else>
    <template #middle>
      <validation-observer
        ref="refForm"
      >
        <app-account-form title="기본 정보">
          <!-- 채널 계정 이름 입력 -->
          <name-form
            type="채널계정"
            inject-key="currentAccount"
            :read-only="isReadOnly"
          />
          <!-- url 추가 -->
          <url-repeater :read-only="isReadOnly" />
          <!-- 사업자 등록 번호 입력 -->
          <tax-number-input :read-only="isReadOnly" />
          <template #submit-button>
            <b-button
              v-if="!isReadOnly"
              class="submit-btn"
              variant="primary"
              block
              :disabled="!canUpdate"
              @click="onUpdate"
            >
              채널계정 수정하기
            </b-button>
          </template>
        </app-account-form>

        <app-account-form title="멤버">
          <!-- 기존 계정 멤버 이메일 및 권한 수정 -->
          <members :read-only="isReadOnly" />
          <template #submit-button>
            <b-button
              v-if="!isReadOnly"
              class="submit-btn"
              variant="primary"
              block
              :disabled="!permissionState"
              @click="onEditPermission"
            >
              멤버권한 수정하기
            </b-button>
          </template>
        </app-account-form>
      </validation-observer>

      <validation-observer
        ref="refInvite"
      >
        <app-account-form
          v-if="!isReadOnly"
          title="멤버 초대"
        >
          <!-- 신규 계정 멤버 초대 -->
          <member-repeater />
          <template #submit-button>
            <b-button
              class="submit-btn"
              variant="primary"
              block
              :disabled="newMembers.length === 0"
              @click="sendInvitation"
            >
              초대하기
            </b-button>
          </template>
        </app-account-form>
      </validation-observer>

    </template>
  </app-form-layout>

</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BButton,
} from 'bootstrap-vue'
import {
  computed, onMounted,
} from '@vue/composition-api'
import useAccount from '@/views/utils/account/useAccount'
import useLocaleValidation from '@/views/utils/useLocaleValidation'
import useAccountTask from '@/views/utils/task/useAccountTask'
import store from '@/store'
import AppAccountForm from '@/views/layouts/AppAccountForm.vue'
import TaxNumberInput from '@/views/components/account/TaxNumberInput.vue'
import UrlRepeater from '@/views/components/account/UrlRepeater.vue'
import MemberRepeater from '@/views/components/account/MemberRepeater.vue'
import Members from '@/views/components/account/Members.vue'
import { canManageChannelAccount } from '@/libs/acl/protection'
import AppFormLayout from '@/views/layouts/AppFormLayout.vue'
import NameForm from '@/views/components/NameForm.vue'

export default {
  components: {
    AppFormLayout,
    AppAccountForm,
    NameForm,
    TaxNumberInput,
    UrlRepeater,
    MemberRepeater,
    BButton,
    Members,
    ValidationObserver,
  },
  setup() {
    useLocaleValidation()
    const {
      refForm,
      refInvite,
      canUpdate,
      permissionState,
      assignedMembers,
      newMembers,
      currentAccount,
      validate,
      setCurrentAccount,
      makeAccountData,
    } = useAccount()

    const {
      loading,
      error,
      fetchChannelAccount,
      updateChannelAccount,
      editChannelAccountPermission,
    } = useAccountTask()

    const onUpdate = async () => {
      if (await validate()) {
        const account = makeAccountData()
        const newAccount = await updateChannelAccount(account)
        setCurrentAccount(newAccount)
      }
    }

    const onEditPermission = async () => {
      const promises = []
      const accountId = currentAccount.id.value
      assignedMembers.value.forEach(member => promises.push(editChannelAccountPermission({
        accountId,
        memberId: member.id,
        permission: member.permission,
      })))
      await Promise.all(promises)
    }

    const sendInvitation = async () => {
      refInvite.value.validate()
    }

    const isReadOnly = computed(() => !canManageChannelAccount())

    // 컴포넌트 마운트 시 데이터 fetch
    onMounted(async () => {
      const id = store.getters['account/accountId']
      const account = await fetchChannelAccount(id)
      setCurrentAccount(account)
    })

    return {
      loading,
      error,
      refForm,
      refInvite,
      newMembers,
      permissionState,
      canUpdate,
      onUpdate,
      sendInvitation,
      onEditPermission,
      isReadOnly,
    }
  },
}
</script>
