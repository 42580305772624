<template>
  <form-slot-layout title="이메일">
    <template #title-suffix>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        pill
        size="sm"
        variant="primary"
        @click="repeateAgain"
      >
        <b-icon
          icon="plus"
        />
        추가
      </b-button>
    </template>
    <b-form
      ref="form"
      :style="{height: trHeight}"
      class="repeater-form"
      @submit.prevent="repeateAgain"
    >

      <!-- Row Loop -->
      <div
        v-for="(member, index) in newMembers"
        :id="member.id"
        :key="member.id"
        ref="row"
        class="repeater-row"
      >
        <!-- Email -->
        <validation-provider
          #default="{ errors }"
          name="이메일"
          rules="required|email"
          class="repeater-input"
        >
          <div class="repeater-info">
            <b-form-input
              v-model="member.email"
              type="email"
              class="input-form"
            />
            <!-- Permission -->
            <b-dropdown
              v-ripple="'rgba(113, 102, 240, 0.15)'"
              :text="member.permission.text"
              variant="primary"
              class="repeater-dropdown"
              block
              right
            >
              <b-dropdown-item
                v-for="permission in permissionOptions"
                :key="permission.text"
                @click="member.permission = permission"
              >
                {{ permission.text }}
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item>
                결제 담당(읽기 전용)
              </b-dropdown-item>
              <b-dropdown-item>
                기술 담당(읽기/편집)
              </b-dropdown-item>
            </b-dropdown>

            <!-- Remove Button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              pill
              size="sm"
              variant="danger"
              class="delete-btn"
              :disabled="newMembers.length === 1"
              @click="removeItem(index)"
            >
              <b-icon
                icon="x"
              />
              삭제
            </b-button>
          </div>

          <small class="text-danger">{{ errors[0] }}</small>

        </validation-provider>
      </div>
    </b-form>
  </form-slot-layout>

</template>

<script>
import {
  BForm, BFormInput, BButton,
  BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider } from 'vee-validate'
import { inject } from '@vue/composition-api'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    BForm,
    BButton,
    BFormInput,
    ValidationProvider,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.newMembers.push({
        email: '',
        permission: {
          text: '읽기 전용',
          value: 'READ',
        },
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.newMembers.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form?.scrollHeight)
      })
    },
  },
  setup() {
    const options = inject('permissionOptions')
    const newMembers = inject('newMembers')
    return {
      permissionOptions: options.slice(0, options.length - 1),
      newMembers,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  font-size: large;
  overflow: visible;
  transition: .35s height;
  min-height: 60px;

  .repeater-row {
    margin-bottom: 20px;

    .repeater-info {
      display: flex;
      align-items: center;

      .input-form {
        flex-grow: 1;
      }

      .repeater-dropdown {
        margin-left: 20px;
        white-space: nowrap;
        width: 40%;
      }

      .delete-btn {
        margin-left: 20px;
        white-space: nowrap;
      }
    }
  }
}
</style>
